import React, {useEffect, useState} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import { Dashboard } from './components/Dashboard';

const apiBase = 'https://finderdata.co/api/';

function App() {
    return (
        <Router>
            <Switch>
                <Route
                    path={'/login'}
                    component={() => {
                        window.location.href = apiBase + 'auth/google';
                        return null;
                    }}
                />
                <Route>
                    <Dashboard />
                </Route>
            </Switch>
        </Router>
    );
}

export default App;
