import React, {useEffect, useState} from 'react';
import { withRouter } from 'react-router-dom';
import '../App.css';
import 'luna/src/luna.scss';
import { Services } from './Services';
import { Processes } from "./Processes";
import { Authorisation } from './Authorisation';

const apiBase = 'https://finderdata.co/api/'

const apiGet = (endpoint) => {
    return fetch(
        apiBase + endpoint,
        {
            credentials: "include"
        });
};

const DashboardDiv = withRouter(({history}) => {
    {
        const [svcs, setSvcs] = useState([]);
        const [procs, setProcs] = useState([]);
        const [session, setSession] = useState(null);
        const [currentIp, setCurrentIp] = useState(null);

        const logout = () => {
            apiGet('auth/logout')
                .then(res => res.json())
                .then(res => {
                    if (res.successful) {
                        window.location.reload(false);
                    }
                })
        }

        useEffect(() => {
            apiGet('auth')
                .then(res => res.json())
                .then(res => {
                    setSession(res);
                });
        },[])

        useEffect(() => {
            if (session && !session.successful) {
                history.push('/login');
            }
        },[session])

        useEffect(() => {
            apiGet('services')
                .then(res => res.json())
                .then(res => {
                    setSvcs(res);
                });
        },[])

        useEffect(() => {
            apiGet('processes')
                .then(res => res.json())
                .then(res => {
                    setProcs(res);
                });
        },[])

        useEffect(() => {
            fetch('https://icanhazip.com')
                .then(res => res.json())
                .then(res => {
                    setCurrentIp(res);
                })
        },[])


        if (session) {
            if (session.successful) {
                return (
                    <div className="App">
                        <header className="App-header">
                            <h2>
                                Finder Innovation Technology
                            </h2>
                            <p>
                                <em>Hi, {session.user.name}</em>
                                <span style={{paddingRight: '2px', paddingLeft: '2px'}}> &bull; </span>
                                <a onClick={logout}>Logout</a>
                            </p>
                            <h3>
                                IP Authorisation
                            </h3>
                            <Authorisation
                                ip={session.user.lastIp}
                                currentIp={currentIp}
                            />
                            <h3>
                                Services &amp; Tools
                            </h3>
                            <Services svcs={svcs} />
                            <p>
                                <strong>
                                    For support, please Slack
                                    #data-innovation or @gabe
                                </strong>
                            </p>
                            <p>
                                &copy; {new Date().getFullYear()} Finder
                                <br />
                                Authorised use only
                            </p>
                        </header>
                        <Processes procs={procs} />
                    </div>
                );
            } else {
                return (<div>Redirecting...</div>);
            }
        } else {
            return (<div>Loading...</div>);
        }
    }
})

export function Dashboard() {
    return (<DashboardDiv />);
}
