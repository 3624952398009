import React, {useState} from 'react';
import {Card, Grid, Column, Button} from 'luna-react';

const apiBase = 'https://finderdata.co/api/';

const apiGet = (endpoint) => {
    return fetch(
        apiBase + endpoint,
        {
            credentials: "include"
        });
};


export function Authorisation(props) {
    const [authorisingAlert, setAuthorisingAlert] = useState(false);

    const authoriseIp = (() => {
        setAuthorisingAlert(true);
        apiGet('ip/auth')
            .then(() => {
                window.location.reload(false);
            })
    })

    return(
        <Grid align={'center'}>
            <Column>
                <Card border>
                    {authorisingAlert &&
                        <Card
                            className={'luna-card--primary'}
                        >
                            Authorising {props.currentIp}, will refresh when completed successfully...
                        </Card>
                    }
                    {props.currentIp === '59.100.201.238' &&
                        <p>You are on the Sydney Office network.</p>
                    }
                    {props.currentIp !== '59.100.201.238' &&
                        <p>Your currently authorised IP is <strong>{props.ip}</strong>.</p>
                    }
                    <Button
                        className={'luna-button--primary'}
                        label={'Update IP'}
                        disabled={props.ip === props.currentIp || ['59.100.201.238'].includes(props.ip) ? true : false}
                        onClick={authoriseIp}
                    />
                </Card>
            </Column>
        </Grid>
    )
}
