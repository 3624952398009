import React from 'react';
import {Accordion, Card, Grid, Column} from 'luna-react';

export function Processes(props) {

    return(
        <Grid align={'center'}>
            <Column width={'2/3'}>
                <Card>
                    <Accordion
                        title={'Running Processes'}
                        className={'luna-accordion__action'}
                    >
                        <small>{props.procs.join(', ')}</small>
                    </Accordion>
                </Card>
            </Column>
        </Grid>
    )
}
