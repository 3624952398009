import React from 'react';
import {Button, CardAction, Card, Grid, Column} from "luna-react";

export function Services(props) {
    const buttonArr = [];
    const buttonColsArr = [[],[],[]];

    if (props.svcs) {
        props.svcs.forEach((s,i) => {
            buttonArr.push(
                <Card
                    border
                    className={'service-card__container'}
                >
                    <h1 className="luna-card__title">
                        <a href={`https://${s['finder-data.host']}`}>{s['finder-data.name']}</a>
                    </h1>
                    <p className={'service-card__desc'}>{s['finder-data.description']}</p>
                        <CardAction
                            href={`https://${s['finder-data.host']}`}
                        >
                            <Button
                                id={`service-${i}`}
                                label={s['finder-data.host']}
                                modifiers='primary'
                            />
                        </CardAction>
                </Card>
            )
        })

        const cardsPerCol = Math.ceil(buttonArr.length / 2);

        for (let line = 0; line < 2; line++) {
            for (let i = 0; i < cardsPerCol; i++) {
                const value = buttonArr[i + line * cardsPerCol];
                if (!value) continue;
                buttonColsArr[line].push(value);
            }
        }
    }

    return(
        <Grid>
            <Column s='1/1' m='1/2' l='1/2' lLast>
                {buttonColsArr[1]}
            </Column>
            <Column s='1/1' m='1/2' l='1/2'>
                {buttonColsArr[0]}
            </Column>
        </Grid>
    )
}
